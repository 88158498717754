<template>
  <svg class="hamburger-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.313401 0 0.7 0H13.3C13.6866 0 14 0.447715 14 1C14 1.55228 13.6866 2 13.3 2H0.7C0.313401 2 0 1.55228 0 1Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7C0 6.44772 0.313401 6 0.7 6H13.3C13.6866 6 14 6.44772 14 7C14 7.55228 13.6866 8 13.3 8H0.7C0.313401 8 0 7.55228 0 7Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13C0 12.4477 0.313401 12 0.7 12H13.3C13.6866 12 14 12.4477 14 13C14 13.5523 13.6866 14 13.3 14H0.7C0.313401 14 0 13.5523 0 13Z" />
  </svg>
</template>

<style lang="scss">
.hamburger-icon {
  path {
    fill: var(--primary-text);
  }
}
</style>
