import { h, render, type Component } from 'vue';

export const createComponent = (
	component: Component,
	props: Record<string, unknown>,
	parentContainer: Element,
	slots: Record<string, unknown> = {},
	id?: string,
) => {
	const vNode = h(component, props, slots);
	const container = document.createElement('div');
	container.setAttribute('id', id || '');
	parentContainer.appendChild(container);
	render(vNode, container);

	return vNode.component;
};
