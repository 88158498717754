<template>
  <div :class="[
      'onls-button',
      { 'onls-button--small': small },
      { 'onls-button--disabled': disabled },
    ]" @click="onClick">
    {{ text }}
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  text: String,
  disabled: { type: Boolean, default: false },
  small: { type: Boolean, default: false },
});

const emits = defineEmits(['click']);

const onClick = () => {
  if (!props.disabled) {
    emits('click');
  }
}
</script>

<style lang="scss">
.onls-button {
  display: inline-flex;
  border: 2px solid var(--primary-text);
  background: var(--primary-background);
  color: var(--primary-text);
  padding: 12px 25px 12px 20px;
  font-size: 20px;
  border-radius: 5px 50px 50px 5px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-weight: 400;

  &--disabled {
    background: var(--second-background);
    color: var(--secondary-text);
    border-color: var(--secondary-text);
    cursor: default;
  }

  &--small {
    padding: 10px 20px 10px 15px;
    line-height: 16px;
  }

  &:hover {
    background: var(--secondary-background);
  }

  @media screen and (max-width: 768px) {
    padding: 7px 20px 7px 15px;
    font-size: 12px;
  }
}
</style>
