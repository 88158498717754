<template>
  <svg
      class="chevron-icon"
      width="8"
      height="5"
      viewBox="0 0 8 5"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5286 0.195262C3.78894 -0.0650874 4.21106 -0.0650874 4.4714 0.195262L7.80474 3.52859C8.06509 3.78894 8.06509 4.21105 7.80474 4.4714C7.54439 4.73175 7.12228 4.73175 6.86193 4.4714L4 1.60948L1.13807 4.4714C0.877722 4.73175 0.455612 4.73175 0.195262 4.4714C-0.0650874 4.21105 -0.0650874 3.78894 0.195262 3.52859L3.5286 0.195262Z"
    />
  </svg>
</template>
