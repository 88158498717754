export enum FileTypes {
    IMAGE = 'IMAGE',
    DOCUMENT = 'DOCUMENT',
    EXCEL = 'EXCEL',
    VIDEO = 'VIDEO'
}

export const fileAcceptInputMapper = new Map()
    .set(FileTypes.IMAGE, ['image/*'])
    .set(FileTypes.DOCUMENT, ['.pdf', '.doc', '.docx'])
    .set(FileTypes.EXCEL, ['.csv', '.xls', '.xlsx'])
    .set(FileTypes.VIDEO, ['video/*'])


export const fileTypeFormatMapper = new Map()
    .set(FileTypes.IMAGE, ['jpeg', 'jpg', 'png', 'gif'])
    .set(FileTypes.DOCUMENT, ['pdf', 'doc', 'docx'])
    .set(FileTypes.EXCEL, ['csv', 'xls', 'xlsx']);
