<template>
  <div class="theme-toggle" @click="toggleTheme">
    <div class="theme-toggle__item">
      <dark class="theme-toggle--icon" :class="{'theme-toggle--icon--active': theme === 'dark'}" />
    </div>

    <div class="theme-toggle__item">
      <light class="theme-toggle--icon" :class="{'theme-toggle--icon--active': theme === 'light'}" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Dark from "./icons/dark.vue";
import Light from "./icons/light.vue";
const props = defineProps({
  theme: { type: String, default: 'light' },
});

const emits = defineEmits(['update:theme']);


const toggleTheme = () => {
  emits('update:theme', props.theme === "light" ? "dark" : "light");
};
</script>

<style lang="scss">
.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60px;
  height: 30px;
  border: 1px solid var(--primary-text);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 4px;

  &--icon {
    path {
      fill: #D4D5DB;
    }
    &--active {
      path {
        fill: var(--primary-text) !important;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
  }
}
</style>
