import {createComponent} from "../helpers/create-component";
import { OnlsToast } from '../index';
import {Ref, watch} from "vue";

export interface ToastOptions {
    type: 'success' | 'error' | 'info';
    duration?: number;
    message?: string;
    title?: string;
    loadingPercent?: Ref,
    hideOnRouteChange?: boolean;
    action?: { label: string; callback: () => void };
}

export const DEFAULT_TOAST_OPTIONS: ToastOptions = {
    type: 'info',
    duration: 5000,
    hideOnRouteChange: true,
};

export type DismissFunc = () => void;
export type ToastDismissHanlder = { dismiss: DismissFunc } | undefined;

const open = (options: Partial<ToastOptions>): ToastDismissHanlder => {
    if (!options?.message) {
        return;
    }

    const props: ToastOptions = { ...DEFAULT_TOAST_OPTIONS, ...options };
    const instance = createComponent(OnlsToast, props as Record<string, any>, document.body, undefined, 'onls-toast');

    const dismiss = () => {
        (instance?.refs?.toast as Element)?.parentElement?.remove();
    };

    if (!props.loadingPercent) {
        setTimeout(dismiss, props.duration);
    }

    if (props?.hideOnRouteChange) {
        const unwatch = watch(() => window.location.href, () => {
            dismiss();
            unwatch();
        });
    }

    return {
        dismiss,
    };
};

const success = (message: string, options?: Pick<ToastOptions, 'duration' | 'hideOnRouteChange' | 'action' | 'loadingPercent' | 'title'>) => {
    return open({
        ...options,
        message,
        type: 'success',
    });
};

const error = (message: string, options?: Pick<ToastOptions, 'duration' | 'hideOnRouteChange' | 'action' | 'loadingPercent' | 'title'>) => {
    return open({
        ...options,
        message,
        type: 'error',
    });
};

const info = (message: string, options?: Pick<ToastOptions, 'duration' | 'hideOnRouteChange' | 'action' | 'loadingPercent' | 'title'>) => {
    return open({
        ...options,
        message,
        type: 'info',
    });
};

export const useToast = () => {
    return {
        open,
        success,
        error,
        info,
    };
};
