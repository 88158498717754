<template>
  <svg class="edit-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.20836 19.4445C7.37625 19.3987 7.52929 19.3099 7.65235 19.1868L19.7907 7.04851C20.1197 6.7195 20.3807 6.32891 20.5587 5.89904C20.7368 5.46916 20.8284 5.00843 20.8284 4.54314C20.8284 4.07785 20.7368 3.61711 20.5587 3.18724C20.3807 2.75736 20.1197 2.36677 19.7907 2.03776C19.4617 1.70875 19.0711 1.44776 18.6412 1.26971C18.2113 1.09165 17.7506 1 17.2853 1C16.82 1 16.3593 1.09165 15.9294 1.2697C15.4995 1.44776 15.1089 1.70875 14.7799 2.03776L2.6416 14.1761C2.51854 14.2991 2.42973 14.4522 2.38394 14.6201L1.03524 19.5653C0.940815 19.9115 1.03914 20.2818 1.29289 20.5355C1.54664 20.7893 1.91691 20.8876 2.26312 20.7932L7.20836 19.4445Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="5" y="15.2568" width="16" height="2" rx="1" transform="rotate(-44.7116 5 15.2568)"/>
  </svg>
</template>

<style lang="scss">
.edit-icon {
  path {
    stroke: var(--primary-text);
  }

  rect {
    fill: var(--primary-text);
  }
}
</style>
