<template>
  <svg
      class="logo"
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M14.625 11.79L12.195 5.22C11.88 4.41 11.745 3.6 11.745 2.79C11.745 0.945001 12.69 0 14.625 0C16.47 0 17.415 0.900001 17.415 2.7C17.415 3.69 17.28 4.5 16.965 5.22L14.625 11.79ZM10.755 13.86L3.825 12.6C2.07 12.285 0 11.16 0 9.585C0 8.235 1.215 6.39 2.7 6.39C3.78 6.39 4.995 7.065 6.255 8.55L10.755 13.86ZM18.495 13.86L22.995 8.55C24.03 7.335 25.425 6.39 26.505 6.39C27.945 6.39 29.16 8.235 29.16 9.585C29.16 11.16 27.09 12.375 25.335 12.6L18.495 13.86ZM14.625 19.53C12.78 19.53 11.295 18.135 11.295 16.2C11.295 14.355 12.78 12.87 14.625 12.87C16.47 12.87 17.955 14.355 17.955 16.2C17.955 18.09 16.47 19.53 14.625 19.53ZM2.655 26.01C1.08 26.01 0 24.12 0 22.815C0 21.24 2.07 20.115 3.825 19.8L10.755 18.54L6.255 23.85C5.04 25.29 3.78 26.01 2.655 26.01ZM26.46 26.01C25.38 26.01 23.985 25.02 22.995 23.85L18.495 18.54L25.335 19.8C27.09 20.115 29.16 21.24 29.16 22.815C29.16 24.21 28.08 26.01 26.46 26.01ZM14.625 32.4C12.69 32.4 11.745 31.365 11.745 29.34C11.745 28.62 11.925 27.9 12.195 27.18L14.625 20.61L16.965 27.18C17.235 27.99 17.415 28.8 17.415 29.61C17.415 31.455 16.47 32.4 14.625 32.4Z"
    />
  </svg>
</template>

<style lang="scss">
.logo {
  cursor: pointer;

  path {
    fill: var(--primary-text);
  }
}
</style>
