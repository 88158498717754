<template>
  <svg class="filter-icon" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.0844504 0.521211C0.233412 0.203363 0.55517 0 0.909102 0H19.0909C19.4448 0 19.7666 0.203363 19.9156 0.521211C20.0645 0.839059 20.0136 1.2136 19.7851 1.48115L12.7273 9.74353V17.1C12.7273 17.4119 12.5641 17.7016 12.2961 17.8656C12.0281 18.0296 11.6934 18.0445 11.4116 17.905L7.77526 16.105C7.46728 15.9525 7.27273 15.6409 7.27273 15.3V9.74353L0.214948 1.48115C-0.0135947 1.2136 -0.0645115 0.839059 0.0844504 0.521211ZM2.86847 1.8L8.87597 8.83285C9.01476 8.99532 9.09091 9.20122 9.09091 9.414V14.7438L10.9091 15.6438V9.414C10.9091 9.20122 10.9852 8.99532 11.124 8.83285L17.1315 1.8H2.86847Z"/>
  </svg>
</template>

<style lang="scss">
.filter-icon {
  path {
    fill: var(--primary-text);
  }
}
</style>
