<template>
  <div ref="toast"
       class="onls-toast"
       :class="[`onls-toast--${type}`, { 'onls-toast-absolute': !static }]"
  >
    <div class="d-flex jc-between">
      <div class="d-flex ai-center">
        <div class="onls-toast__title">{{ title }}</div>
      </div>

      <div v-if="!static" class="onls-toast__close" @click="onRemove"/>
    </div>

    <div v-show="message.length" class="onls-toast__message">{{ message }}</div>

    <div v-show="!!action" class="onls-toast__button" @click="action?.callback()">
      <span v-if="action?.label">{{ action.label }}</span>

      <small-arrow-icon/>
    </div>

    <div v-if="!static && duration" class="onls-toast__linear">
      <div class="onls-toast__linear-determinate" :style="{width: `${getPercentValue}%`}"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref, watch} from "vue";
import {SmallArrowIcon} from "../index";

const props = defineProps({
  type: { type: String, default: 'success' }, // success, info, error
  static: { type: Boolean, default: false },
  title: { type: String, default: '' },
  message: { type: String, default: '' },
  action: { type: Object, default: undefined }, // { label, callback }
  duration: { type: Number },
  loadingPercent: { type: Object },
});

const linearPercent = ref(0);

const toast = ref<Element | null>(null);

const onRemove = () => {
  toast.value?.parentElement?.remove();
}

const getPercentValue = computed(() => props.loadingPercent?.value || (linearPercent.value / (props?.duration as number)) * 100);

onMounted(() => {
  if (props.duration && !props.static && !props.loadingPercent) {
    const id = setInterval(() => {
      if (linearPercent.value >= (props?.duration as number)) {
        clearInterval(id)
      } else {
        linearPercent.value += 10;
      }
    }, 10);
  }
});

watch(() => props.loadingPercent?.value, () => {
  if (props.loadingPercent?.value === 100) {
    onRemove();
  }
}, { immediate: true })
</script>

<style lang="scss">
.onls-toast {
  width: 450px;
  border: 2px solid var(--primary-text);
  padding: 22px 0 0;
  border-radius: 5px;
  background: var(--primary-background);

  img {
    margin-left: 26px;
  }

  &-absolute {
    right: 50px;
    bottom: 50px;
    position: fixed;
    z-index: 999;
  }

  &__title {
    padding: 0 13px;
    font-size: 15px;
    color: var(--primary-text);
    text-transform: uppercase;
  }

  &__message {
    font-size: 14px;
    margin: 20px 26px;
    color: var(--primary-text)
  }

  &__close {
    margin-right: 26px;
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 70px;
    cursor: pointer;

    &:hover {
      &:before,
      &:after {
        background-color: var(--primary-text);
      }
    }

    &:before,
    &:after {
      position: absolute;
      content: "";
      height: 16px;
      width: 2px;
      background-color: var(--primary-text);
      left: 50%;
      border-radius: 5px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 26px 22px;
    color: var(--primary-text);

    .small-arrow-icon {
      margin-left: 10px;
      width: 20px;
      height: 15px;

      path {
        fill: var(--primary-text);
      }
    }
  }

  &__linear {
    height: 20px;
    width: 100%;
    background: #d9d9d9;

    &-determinate {
      position: relative;
      max-width: 100%;
      height: 100%;
      -webkit-transition: width 500ms ease-out 0s;
      -moz-transition: width 500ms ease-out 0s;
      -o-transition: width 500ms ease-out 0s;
      transition: width 500ms ease-out 0s;
    }
  }

  &--info {
    .onls-toast {
      &__linear-determinate {
        background-color: var(--primary-text);
      }
    }
  }

  &--success {
    .onls-toast {
      &__linear-determinate {
        background-color: #65DC63;
      }
    }
  }
}
</style>
