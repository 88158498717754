<template>
  <div class="login-form__container">
    <div class="login-form__icon">
      <logo-icon @click="emits('click:logo')"/>

      <div class="login-form__icon--switch">
        <onls-theme-toggle :theme="theme" @update:theme="emits('update:theme')"/>

        <onls-lang-switcher :locale="locale" :other-locale="otherLocale" @update:locale="emits('update:locale', $event)"/>
      </div>
    </div>

    <div class="login-form">
      <div v-if="isShowOtp" class="login-form__title">
        <div>{{ languages.code }}</div>
      </div>
      <div v-else class="login-form__title">
        <div>{{ languages.getStarted }},</div>
        <div>{{ languages.insertEmail }}</div>
      </div>

      <div v-if="!isShowOtp" class="login-form__subtitle">
        {{ languages.description }}
      </div>

      <template v-if="isShowOtp">
        <div v-if="isAvailableToChangeEmail" class="login-form__paste">
          <div>
            {{ languages.pastCode }} <b>{{ email }}</b>
            <span
                class="login-form__paste-change"
                @click="onChangeEmail"
            >({{ languages.change }})</span>
          </div>
        </div>

        <slot name="otp"/>

        <div class="login-form__button">
          <div>{{ languages.next }}</div>
          <span>→</span>
        </div>

        <div class="login-form__resend" @click="onNextClick">
          <div>{{ languages.receive }} <b>{{ languages.resend }}</b></div>
        </div>
      </template>

      <template v-else>
        <div>
          <onls-field
              v-model:value="email"
              :placeholder="languages.email"
              :error-message="getUserEmailError"
              @keyup.enter="onNextClick"
          />

          <div class="login-form__button" @click="onNextClick">
            <div>{{ languages.next }}</div>
            <span>→</span>
          </div>

          <div class="login-form__hr">
            <span></span>
            <div>{{ languages.emailOrGoogleAcc }}</div>
            <span></span>
          </div>

          <div class="login-form__google" @click="loginWithGoogle">
            <google-icon />
            <div>{{ languages.loginWithGoogle }}</div>
          </div>
        </div>
      </template>
    </div>

    <div class="login-form__footer">
      <div>{{ languages.howItWorks }} <b>{{ languages.tryToDemo }}</b></div>
      <span class="login-form__footer--icon" @click="goToDemo">→</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, type PropType } from "vue";
import { OnlsField, LogoIcon, OnlsLangSwitcher, OnlsThemeToggle, GoogleIcon } from "../../index";
import type { LanguageProp } from "./lang-prop.type";

const props = defineProps({
  isShowOtp: { type: Boolean, required: true },
  languages: { type: Object as PropType<LanguageProp>, required: true },
  locale: { type: String, required: true },
  otherLocale: { type: Array as PropType<Array<string>>, required: true },
  theme: { type: String, required: true },
  isAvailableToChangeEmail: { type: Boolean, default: true }
});

const emits = defineEmits(['click:next', 'submit', 'click:change', 'click:google', 'click:logo', 'update:locale', 'update:theme']);

const email = ref("");
const isTriggeredField = ref(false);

const isValidUserEmail = computed(() => {
  return !!email.value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
});

const getUserEmailError = computed(() => {
  return isTriggeredField.value && !isValidUserEmail.value
      ? props.languages.invalidEmail
      : "";
});

const loginWithGoogle = () => {
  emits('click:google');
};

const onNextClick = () => {
  isTriggeredField.value = true;
  if (isValidUserEmail.value) {
    emits('click:next', email.value);
  }
};

const onChangeEmail = () => {
  emits('click:change');
  email.value = "";
  isTriggeredField.value = false;
};

const goToDemo = () => {
  window.open("https://demo.onls.me/", "_self");
}
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  cursor: default;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    background: var(--primary-text);
    color: var(--primary-background);
    width: 100%;
    height: 50px;
    border-radius: 12px;

    span {
      font-size: 20px;
      margin-left: 16px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 475px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--switch {
      display: flex;
      position: relative;
      gap: 16px;
      width: calc(60px * 2 + 16px);
    }
  }

  &__title {
    margin-bottom: 15px;
    div {
      font-size: 32px;
      color: var(--primary-text);
      font-weight: 400;
    }
  }

  &__subtitle {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 300;
    color: var(--third-text);
  }

  &__hr {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    span {
      height: 1px;
      width: 100%;
      background: #D4D5DB;
    }

    div {
      font-weight: 400;
      font-size: 16px;
      color: #D4D5DB;
      min-width: max-content;
    }
  }

  &__google {
    border: 1px solid var(--primary-text);
    border-radius: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 400;
    color: var(--primary-text);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    div {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .google-icon {
      margin-right: 10px;
    }
  }

  &__otp {
    &-container {
      column-gap: 20px;
      margin-bottom: 24px;
    }

    &-input {
      text-align: center;
      width: 100%;
      aspect-ratio: 1;
      border-radius: 12px;
      border: 1px solid #DADADA;
      font-size: 40px;
      outline: none;
      background-color: transparent;
      color: var(--primary-text);

      &:focus,
      &:focus-visible {
        border-color: var(--primary-text) !important;
      }
    }
  }

  &__paste {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 300;
    color: var(--secondary-text);

    b {
      color: var(--primary-text);
      font-weight: 400;
    }

    &-change {
      text-transform: lowercase;
      cursor: pointer;
      color: var(--primary-text);
      font-weight: 400;
      margin-left: 5px;
    }
  }

  &__resend {
    cursor: pointer;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 300;
    color: var(--secondary-text);

    b {
      color: var(--primary-text);
      font-weight: 400;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    border-top: 1px solid #D4D5DB;
    font-size: 16px;

    color: var(--third-text);
    font-weight: 300;

    b {
      color: var(--primary-text);
      font-weight: 400;
    }

    &--icon {
      margin-left: 15px;
      border: 1px solid var(--primary-text);
      border-radius: 5px;
      padding: 0 12px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-text);
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__container {
      padding-top: 0;
      width: 100%;
    }

    &__title {
      div {
        font-size: 28px !important;
      }
    }
  }

  @media screen and (max-width: 640px) {
    &__otp-container {
      column-gap: 10px !important;
    }
    &__otp-input {
      font-size: 28px !important;
    }
  }
}
</style>
