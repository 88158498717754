<template>
  <div class="onls-main-field">
    <div v-if="label" class="field-label">{{ label }}</div>

    <div
        :class="[
            'onls-main-field__container',
        { 'onls-main-field__container--active': value.length,
          'onls-main-field__container--error': errorMessage.length,
          'onls-main-field__container--disabled': disabled,
         }]"
    >
      <input
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          :type="type"
          @input="onInput"
      />
    </div>

    <onls-field-error :error-message="errorMessage"/>
  </div>
</template>

<script setup lang="ts">
import { OnlsFieldError } from "../index";

defineProps({
  value: { type: String, default: "" },
  label: String,
  placeholder: String,
  disabled: Boolean,
  errorMessage: { type: String, default: "" },
  type: { type: String, default: "text" },
});

const emits = defineEmits(['update:value']);

const onInput = ($event: any) => {
  emits('update:value', $event.target?.value);
}
</script>

<style lang="scss">
.onls-main-field {
  width: 100%;

  &__container {
    border: 2px solid var(--secondary-text);
    border-radius: 5px;

    &--active {
      border-color: var(--primary-text);
    }

    &--error {
      border-color: #c60010;
    }

    &--disabled {
      border: 2px solid var(--secondary-text);
      background: #f2f2f2;
    }
  }

  input {
    padding: 15px 20px;
    border: none;
    outline: none;
    font-size: 16px;
    background: inherit;
    color: var(--primary-text);
    border-radius: 0;
    width: 100%;

    &::placeholder {
      color: var(--secondary-text);
    }
  }

  &__error {
    opacity: 1;
    min-height: 18px;
    margin-top: 10px;
    color: #c60010;

    &--hide {
      opacity: 0;
    }
  }
}
</style>
