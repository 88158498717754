<template>
  <div class="language-switcher__container">
    <div
        :class="[
      'language-switcher',
      { 'language-switcher--is-open': isShowAllLocales },
    ]"
        @click="isShowAllLocales = !isShowAllLocales"
    >
      <div class="language-switcher__item">
        <svg class="language-switcher__icon" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0.53125C6.42393 0.53125 4.88326 0.998609 3.5728 1.87423C2.26235 2.74984 1.24097 3.99439 0.637838 5.45049C0.0347026 6.90659 -0.123105 8.50884 0.184371 10.0546C0.491847 11.6004 1.2508 13.0203 2.36525 14.1348C3.4797 15.2492 4.89959 16.0082 6.44538 16.3156C7.99116 16.6231 9.59341 16.4653 11.0495 15.8622C12.5056 15.259 13.7502 14.2377 14.6258 12.9272C15.5014 11.6167 15.9688 10.0761 15.9688 8.5C15.9663 6.38732 15.1259 4.36188 13.632 2.86798C12.1381 1.37409 10.1127 0.533731 8 0.53125ZM15.0156 8.03125H11.5844C11.4945 5.63125 10.718 3.35703 9.40157 1.60938C10.9108 1.91876 12.2769 2.7143 13.2909 3.87416C14.3049 5.03401 14.9107 6.49425 15.0156 8.03125ZM8 15.5312C7.98052 15.5314 7.96124 15.5273 7.94346 15.5194C7.92567 15.5114 7.90979 15.4997 7.89688 15.4852C6.36875 13.8391 5.45469 11.4852 5.35313 8.96875H10.6461C10.5453 11.4852 9.63047 13.8391 8.10313 15.4852C8.09022 15.4997 8.07434 15.5114 8.05655 15.5194C8.03876 15.5273 8.01948 15.5314 8 15.5312ZM5.35313 8.03125C5.45469 5.51484 6.36875 3.16094 7.89688 1.51484C7.90985 1.50032 7.92574 1.48871 7.94351 1.48075C7.96128 1.4728 7.98053 1.46869 8 1.46869C8.01947 1.46869 8.03872 1.4728 8.0565 1.48075C8.07427 1.48871 8.09016 1.50032 8.10313 1.51484C9.63125 3.16094 10.5453 5.51484 10.6461 8.03125H5.35313ZM6.59844 1.60938C5.28203 3.35703 4.50547 5.63125 4.41563 8.03125H0.984379C1.08932 6.49425 1.69515 5.03401 2.70911 3.87416C3.72307 2.7143 5.08925 1.91876 6.59844 1.60938ZM0.984379 8.96875H4.41563C4.50547 11.3687 5.28203 13.643 6.59844 15.3906C5.08925 15.0812 3.72307 14.2857 2.70911 13.1258C1.69515 11.966 1.08932 10.5057 0.984379 8.96875ZM9.40157 15.3906C10.718 13.643 11.4945 11.3687 11.5844 8.96875H15.0156C14.9107 10.5057 14.3049 11.966 13.2909 13.1258C12.2769 14.2857 10.9108 15.0812 9.40157 15.3906Z" fill="#0E0E0E"/>
        </svg>

        <span>
          {{ locale }}
        </span>
      </div>

      <template v-if="isShowAllLocales">
        <div
            v-for="(item, i) in otherLocale"
            :key="i"
            class="language-switcher__item"
            @click="$emit('update:locale', item)"
        >
          {{ item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type PropType, ref} from "vue";

defineProps({
  locale: { type: String, required: true },
  otherLocale: { type: Array as PropType<Array<string>>, required: true },
});

defineEmits(['update:locale']);


const isShowAllLocales = ref(false);
</script>

<style lang="scss">
.language-switcher {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30px;
  width: 60px;
  border: 1px solid var(--primary-text);
  border-radius: 6px;
  color: var(--primary-text);
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  cursor: pointer;
  background: var(--primary-background);

  &__container {
    position: relative;
    width: 60px;
    height: 30px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;
    font-size: 14px;
  }

  &__icon {
    min-width: 16px;
    min-height: 17px;
    path {
      fill: var(--primary-text);
    }
  }

  span {
    margin-left: 6px;
    line-height: 17px;
  }

  &--is-open {
    height: auto;
  }
}
</style>
