<template>
  <div :class="[loaderClass, 'onls-skeleton']">
    <div class="onls-skeleton__shimmer" :style="shimmerStyle"></div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import {computed, PropType, toRefs} from 'vue';
import { LOADER_TYPES, type LoaderTypesValues } from "./skeleton.type";

const LOADER_CSS_CLASSES = {
  [LOADER_TYPES.rectangle]: 'rounded',
  [LOADER_TYPES.circle]: 'rounded-full',
};

const SHIMMER_COLOR = '#D9D9D9';

const props = defineProps({
  type: {
    type: String as PropType<'rectangle' | 'circle'>,
    default: LOADER_TYPES.rectangle,
    validator(value: LoaderTypesValues) {
      return Object.values(LOADER_TYPES).includes(value);
    },
  },
});

const { type } = toRefs(props);

const loaderClass = computed(() => LOADER_CSS_CLASSES[type?.value]);

const hexToRgb = (hex: string) => `${hex.match(/\w\w/g)?.map((x) => +`0x${x}`)}`;

const shimmerStyle = computed(() => {
  const rgb = hexToRgb(SHIMMER_COLOR);


  return {
    'background': `linear-gradient(90deg, rgba(${rgb}, 0.4) 0%, rgba(${rgb}, 0.8) 20%, rgba(${rgb}, 1) 60%, rgba(${rgb}, 0.4))`,
  };
});
</script>

<style lang="scss" scoped>
.onls-skeleton {
  position: relative;
  overflow: hidden;

  &__shimmer {
    transform: translateX(-100%);
    animation: shimmer 1.4s infinite;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.rounded {
  border-radius: 0;
}

.rounded-full {
  border-radius: 9999px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
