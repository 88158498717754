<template>
  <div class="onls-input">
    <input
      :value="value"
      :placeholder="placeholder"
      @input="onInput"
    />
    <div
      :class="[
        'onls-input__error',
        { 'onls-input__error--hide': !errorMessage.length },
      ]"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  value: [String, Number],
  placeholder: String,
  errorMessage: { type: String, default: "" },
});

const emits = defineEmits(['update:value']);

const onInput = ($event: any) => {
  emits('update:value', $event.target?.value);
}
</script>

<style lang="scss">
.onls-input {
  width: 100%;
  input {
    width: 100%;
    padding: 0 24px;
    height: 50px;
    display: flex;
    align-items: center;
    border: 1px solid #DADADA;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    background: inherit;
    color: var(--primary-text);
    border-radius: 12px;

    &:focus,
    &:focus-visible {
      border: 1px solid var(--primary-text) !important;
    }
  }

  &__error {
    opacity: 1;
    font-size: 16px;
    margin: 16px 0 24px 0;
    color: #DC4C54;

    &--hide {
      opacity: 0;
    }
  }
}
</style>
